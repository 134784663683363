<template>
  <!-- 结算管理 -->
  <div class="purchase-plan-list settlementManagementPageBox">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :reset="true"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
      @resetForm="getDaterange"
    >
      <el-form-item label="创建时间" label-width="100px">
        <el-date-picker
          v-model="formInline.daterange"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          size="small"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDaterange"
        />
      </el-form-item>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="derivedData">
          导出
        </el-button>
        <!-- <el-button v-if="isShow" size="small" type="primary" @click="settlementCalculate">
          结算费计算
        </el-button> -->
      </div>
      <Table
        :operation-button-width="200"
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 打印的内容  采取定位   让他飘出地球 -->
    <FinalStatement :product-id="productId" class="finalStatementBox" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import FinalStatement from './children/finalStatement.vue'
import {
  agentSettleInfoQueryByPage
} from '@/api/generationMining'
import axios from 'axios'
import { baseURL } from '@/systemConfiguration/index'
import { timestampToTime } from '@/utils/util'
export default {
  components: {
    FormSearch,
    Table,
    FinalStatement,
    Pagination
  },
  data() {
    return {
      paymentObj: { amount: 0 },
      formInline: {
        pageSize: 10,
        pageNum: 1
      },
      isShow: false,
      submitId: '',
      formItemArr: [
        {
          type: 'input',
          label: '合作企业',
          value: 'supplierCmpName',
          width: '120'
        },
        { type: 'input', label: '经销商', value: 'custCmpName', width: '120' },
        { type: 'input', label: '代采商', value: 'fundsCmpName', width: '120' },
        { type: 'input', label: '合同编号', value: 'contractNo', width: '120' },
        {
          type: 'select',
          label: '状态',
          value: 'settleStatus',
          pLabel: 'dictName',
          pValue: 'dictId',
          child: this.$store.getters.getDictionaryItem('SETTESTATUS'),
          width: '70'
        }
      ],
      itemData: [
        { label: '结算单号', prop: 'settleNo', width: 150 },
        { label: '销售合同编号', prop: 'custContractNo', width: 150 },
        { label: '合作企业', prop: 'supplierCmpName', width: 180 },
        { label: '经销商', prop: 'custCmpName', width: 180 },
        { label: '代采商', prop: 'fundsCmpName', width: 120 },
        // { label: '已收金额(元)', prop: 'paymentPaid', width: 120 },
        // { label: '本次支付代采费(元)', prop: 'agentCost', width: 160 },
        { label: '结算总数量(吨)', prop: 'settleTotalWeight', width: 120 },
        { label: '结算总金额(元)', prop: 'totalSettlementAmount', width: 120 },
        { label: '结算差额(元)', prop: 'settleDiff', width: 120 },
        // { label: '未支付垫资货款(元)', prop: 'agentUnpayment', width: 180 },
        // { label: '结算总数量', prop: 'settleTotalAmount', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 140 },

        // { label: '结算日期', prop: 'settleDate', width: 120 },
        {
          label: '状态',
          prop: 'settleStatus',
          width: 180,
          child: this.$store.getters.getDictionaryItem('settleStatus')
        }
      ],
      listData: [],
      operationButton: [
        {
          childOne: [
            { num: 4, val: 'settleStatus' },
            { num: 5, val: 'settleStatus' },
            { num: 6, val: 'settleStatus' }
          ],
          bType: 'primary',
          label: '打印结算单',
          handleEvent: this.turnUpPrinting
        },
        {
          num: 1,
          val: 'settleStatus',
          bType: 'primary',
          label: '详情',
          handleEvent: this.viewInfoTable,
          negation: true
        }
      ],
      loading: false,
      total: 0,
      productId: '', // 打印的id
      dialogTableVisible: false
    }
  },
  methods: {
    // 导出数据
    derivedData() {
      if (this.total === 0) {
        this.$message.error('没有可导出的数据')
        return
      }
      if (this.total > 5000) {
        this.$message.error('导出功能限制5000条，请筛选后再导出')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      axios
        .post(
          baseURL + 'agentSettle/export',
          { ...this.formInline, operFlat: 3 },
          {
            responseType: 'blob', // 设置成文件流
            headers: {
              token: localStorage.getItem('logintoken')
            }
          }
        )
        .then((res) => {
          loading.close()
          if (res.data && res.data.type === 'application/json') {
            this.$message.error('导出失败')
            return
          }
          const link = document.createElement('a')
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          }) // 文件流处理
          link.style.display = 'none' // 去除a标签的样式
          link.href = URL.createObjectURL(blob)
          link.download = `${this.$getUserInfoParameter('orgName')}_结算单数据_${timestampToTime(new Date(), true)}`
          document.body.appendChild(link)
          // 模拟点击事件
          link.click()
          // 移除创建的a标签
          window.URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        }).catch(() => {
          loading.close()
        })
    },
    // 获取时间
    getDaterange(e) {
      this.$forceUpdate()
      if (!e || e.length !== 2) {
        this.formInline.daterange = []
        this.formInline.settleDateStart = ''
        this.formInline.settleDateEnd = ''
        return
      }
      this.formInline.settleDateStart = e[0]
      this.formInline.settleDateEnd = e[1]
    },
    // 调起打印
    turnUpPrinting({ id }) {
      this.productId = id + '&&' + new Date().valueOf()
    },
    // 录入价格
    inputPrices(row) {
      this.$router.push({
        path: '/salesContractManagement/additionalSettlement',
        query: { id: row.id }
      })
    },
    // 获取列表
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentSettleInfoQueryByPage(this.formInline, (res) => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    },
    // 详情
    viewInfoTable(row) {
      this.$router.push({
        path: '/settlementManage/settlementDetails',
        query: {
          agentCost: row.agentCost,
          settleAmount: row.settleAmount,
          settleDiff: row.settleDiff,
          id: row.id
        }
      })
    }
  }
}
</script>
<style lang="scss">
.settlementManagementPageBox {
  position: relative;
  .finalStatementBox {
    position: absolute;
    top: -5000px;
    left: -5000px;
  }
}
</style>
